import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { useAuth } from '../../../context/authContext';
import './Header.css';
import ToggleThemeButton from 'components/ToggleThemeButton';
function Header({ title, toggleTheme, themeMode }) {
  const { setLogin, setProfile, setPermissions } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('platinumRxDashboardUserDetail');
    localStorage.removeItem('platinumRxDashboardToken');
    localStorage.removeItem('user_id');
    setLogin(false);
    setProfile(null);
    setPermissions([]);
  };

  return (
    <nav className='header-nav'>
      <div
        style={{
          fontSize: '20px',
          fontWeight: '700',
          color: '#000000',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <KeyboardBackspaceIcon
          onClick={() => navigate(-1)}
          style={{
            cursor: 'pointer',
            borderRadius: '999px',
            border: '3px solid black',
          }}
        />
        &nbsp; &nbsp; {title}
      </div>
      <div style={{ display: 'flex', gap: '25px', alignItems: 'center' }}>
        <span
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#000000',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/profile')}
        >
          Profile
        </span>
        <span
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#000000',
            cursor: 'pointer',
          }}
          onClick={logout}
        >
          Logout
        </span>
        <ToggleThemeButton toggleTheme={toggleTheme} themeMode={themeMode} />
      </div>
    </nav>
  );
}

export default Header;

import React, { useEffect, useState } from 'react';
import { getWarehousePendingOrders } from 'utils/api';
import OrderSKUDetailModal from './OrderSKUDetailsModal';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { TextField } from '@mui/material';

const PendingOrderListingPage = () => {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [openOrderSKUDetailsModal, setOpenOrderSKUDetailsModal] =
    useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  // const [page, setPage] = useState(0)
  // const [pageSize, setPageSize] = useState(10)
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderIds, setOrderIds] = useState('');

  const fetchPendingOrders = async (page, pageSize) => {
    setLoading(true);
    try {
      const data = {
        params: {
          skip: 0,
          limit: 1500,
        },
        data: {
          orderIds: orderIds
            .split(',')
            .map((orderId) => parseInt(orderId))
            .filter(Boolean),
        },
      };

      const response = await getWarehousePendingOrders()(data);

      if (response) {
        setPendingOrders(response.data.warehousePendingOrders);
        setRowCount(response.data.totalCount); // Assuming the API returns the total number of orders
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingOrders();
  }, [orderIds]);

  const columns = [
    {
      field: 'orderId',
      headerName: 'Order ID',
      width: 100,
      type: 'number',
      valueFormatter: (row, value) => {
        return row ? row.toString() : '';
      },
    },
    {
      field: 'createdAt',
      headerName: 'Order Date',
      width: 100,
      valueGetter: (value, row) => row?.createdAt?.slice(0, 10), // Formatting date
    },
    { field: 'modeOfPayment', headerName: 'Payment Mode', width: 150 },
    { field: 'skus', headerName: '#SKUs', width: 100, type: 'number' },
    {
      field: 'eta',
      headerName: 'ETA',
      width: 100,
      valueGetter: (value, row) => (row?.eta ? row.eta.slice(0, 10) : '-'), // Formatting eta
    },
    {
      field: 'viewSkuDetails',
      headerName: 'View SKU Details',
      width: 200,
      renderCell: (data) => (
        <button
          className={`prx-btn`}
          onClick={() => {
            setOpenOrderSKUDetailsModal(true);
            setSelectedOrder(data.row);
          }}
        >
          View SKU Details
        </button>
      ),
    },
  ];

  const rows = pendingOrders.map((order, index) => ({
    id: order.orderId,
    orderId: order.orderId,
    createdAt: order.createdAt,
    modeOfPayment: order.orderPayment.modeOfPayment,
    skus: order.skus,
    eta: order.eta,
  }));

  return (
    <div className='home'>
      <div className='filter-header'>
        <TextField
          fullWidth
          variant='outlined'
          label='Enter Order Id (comma separated if multiple)'
          name='masterDrugCode'
          onChange={(e) => setOrderIds(e.target.value)}
        />
      </div>
      <div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          loading={loading}
          autoHeight
          disableSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'var(--grey-2)',
              fontWeight: 'bold',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
      </div>

      {openOrderSKUDetailsModal && selectedOrder && (
        <OrderSKUDetailModal
          open={openOrderSKUDetailsModal}
          toggle={() => setOpenOrderSKUDetailsModal(false)}
          orderId={selectedOrder.orderId}
          setOpen={setOpenOrderSKUDetailsModal}
        />
      )}
    </div>
  );
};

export default PendingOrderListingPage;

import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { useAuth } from './context/authContext';
import './App.css';
import Layout from 'containers/Layout/Layout';
import PageLoader from 'components/Loaders/PageLoader';
import PendingOrderListingPage from './pages/Procurement Dashboard/PendingOrder/PendingOrderListing';
import DraftPOView from './pages/Procurement Dashboard/DraftPOView';
import PurchaseOrders from './pages/Procurement Dashboard/Purchase Orders';
import POItemLevel from './pages/Procurement Dashboard/P.O. Item Level';
import ReOrderPoints from './pages/Procurement Dashboard/ReOrderPoints';
import ReOrderPointsSummary from './pages/Procurement Dashboard/ReOrderPointsSummary';
import QCListing from './pages/QC Dashboard/QCListing';
import QCDetails from './pages/QC Dashboard/QCDetails';
import QCSplitDetails from './pages/QC Dashboard/QCDetailsSplitOrders';

const ListingPage = lazy(() =>
  import('./pages/Doctor And Pharmacist/ListingPage/ListingPage')
);
const Login = lazy(() => import('./pages/Login/Login'));
const Sidebar = lazy(() => import('containers/Layout/Sidebar/Sidebar'));
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'));
const Manage = lazy(() => import('./pages/Manage/Manage'));
const OrderPage = lazy(() =>
  import('./pages/Doctor And Pharmacist/OrderPage/OrderPage')
);

const ProcuremetItemListingPage = lazy(() =>
  import('./pages/Procurement Dashboard/Procurement/ProcurementListing')
);

function App() {
  const { login, setLogin, setProfile, setPermissions } = useAuth();

  useEffect(() => {
    const auth = localStorage.getItem('platinumRxDashboardToken');
    if (!auth) setLogin(false);
    let detail = JSON.parse(
      localStorage.getItem('platinumRxDashboardUserDetail')
    );
    if (detail) {
      setProfile(detail);
      let roles = [];
      detail.roles.map((role) => {
        roles = [...roles, ...role.permissions];
        roles.push(role.name);
      });
      setPermissions(roles);
    }
  }, []);

  return (
    <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
      <div className='App'>
        <BrowserRouter>
          <Suspense fallback={<PageLoader />}>
            {!login ? (
              <Login />
            ) : (
              <>
                <Sidebar />
                <Routes>
                  <Route
                    path='/'
                    element={
                      <Layout title='Summary'>
                        <ListingPage />
                      </Layout>
                    }
                  />

                  <Route
                    path='/qc-order/:orderId/:statusId'
                    element={
                      <Layout title=''>
                        <QCDetails />
                      </Layout>
                    }
                  />

                  <Route
                    path='/qc-split-order/:orderId'
                    element={
                      <Layout title=''>
                        <QCSplitDetails />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/items'
                    element={
                      <Layout title='Summary'>
                        <ProcuremetItemListingPage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/DraftPOView'
                    element={
                      <Layout title='Summary'>
                        <DraftPOView />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/pending-orders'
                    element={
                      <Layout title='Summary'>
                        <PendingOrderListingPage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/purchase-orders-item-level'
                    element={
                      <Layout title='Summary'>
                        <POItemLevel />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/purchase-orders'
                    element={
                      <Layout title='Summary'>
                        <PurchaseOrders />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/reorder-points'
                    element={
                      <Layout title='Summary'>
                        <ReOrderPoints />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/reorder-points-summary'
                    element={
                      <Layout title='Summary'>
                        <ReOrderPointsSummary />
                      </Layout>
                    }
                  />
                  <Route
                    path='/order/:orderId/:statusId'
                    element={
                      <Layout title=''>
                        <OrderPage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/qc-listing'
                    element={
                      <Layout title='QCListing'>
                        <QCListing />
                      </Layout>
                    }
                  />
                  <Route
                    path='/manage'
                    element={
                      <Layout title='Manage'>
                        <Manage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/profile'
                    element={
                      <Layout title='Profile'>
                        <ProfilePage />
                      </Layout>
                    }
                  />
                </Routes>
              </>
            )}
          </Suspense>
        </BrowserRouter>
      </div>
    </SnackbarProvider>
  );
}

export default App;

import { Switch, Box } from '@mui/material';
import styles from './toggleThemeButton.module.scss';

const ToggleThemeButton = ({ themeMode, toggleTheme }) => {
  return (
    <Box className={styles.main}>
      <p>{themeMode === 'light' ? 'Light Mode' : 'Dark Mode'}</p>
      <Switch
        className={styles.switch}
        checked={themeMode === 'dark'}
        onChange={toggleTheme}
        color='primary'
        inputProps={{ 'aria-label': 'Toggle Theme' }}
      />
    </Box>
  );
};

export default ToggleThemeButton;

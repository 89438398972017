import React, { useState } from 'react';
import Modal from 'components/CustomElements/Modal';
import styles from './purchase.module.scss';
import { updatePurchaseOrderStatus } from 'utils/api';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';

const UpdateStatusModal = ({
  statusData,
  open,
  setOpen,
  toggle,
  fetchPurchaseOrderItems,
}) => {
  const [orderQuantity, setOrderQuantity] = useState(
    statusData?.receivedQuantity
  );
  const [acknowledge, setAcknowledge] = useState(false);
  const handleSubmit = async (id) => {
    try {
      const data = {
        id,
        data: {
          receivedQuantity: Number(orderQuantity),
          purchaseRate: Number(statusData?.purchaseRate),
          markAsClosed: true,
          eta: statusData.eta,
        },
      };
      await updatePurchaseOrderStatus()(data);

      fetchPurchaseOrderItems();
      setOpen(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <Modal
      openModal={open}
      setOpenModal={setOpen}
      showCloseIcon={true}
      onClose={toggle}
    >
      <div className={styles.modal_padding}>
        <TextField
          fullWidth
          required
          label=' Received Quantity'
          variant='outlined'
          size='small'
          value={orderQuantity}
          onChange={(e) => setOrderQuantity(e.target.value)}
          type='number'
          margin='normal'
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={acknowledge}
              onChange={(e) => setAcknowledge(e.target.checked)}
              color='primary'
            />
          }
          label='Acknowledge we have recieved the above mentioned quantity and close the PO item'
        />

        <div>
          <p className={styles.noteText}>
            * This item will no longer be considered
          </p>
        </div>

        <button
          disabled={!acknowledge} // Disable the button until the checkbox is checked
          onClick={() => handleSubmit(statusData?.id)}
          className={acknowledge ? 'prx-btn' : 'prx-disabled-btn'}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default UpdateStatusModal;

import React, { useState } from 'react';
import Modal from 'components/CustomElements/Modal';
import styles from './UploadCSV.module.scss';
import SelectReorderFiles from '../SelectReorderFiles';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload_icon.svg';
import { putDrugReorderPoint } from 'utils/api';
import { enqueueSnackbar } from 'notistack';
import PdfSvg from '../../../assets/icons/PdfSvg';
import { IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
const UploadCSVModal = ({ open, setOpen, handleOnClose }) => {
  const [openReorderUploads, setOpenReorderUploads] = useState(false);
  const [ReorderFiles, setReorderFiles] = useState(null);

  const handleSubmit = async () => {
    try {
      const data = new FormData();
      data.append('file', ReorderFiles);
      await putDrugReorderPoint()(data);
    } catch (e) {
      console.log('error', e);
      enqueueSnackbar(e, { variant: 'error' });
    } finally {
      setOpen(false);
    }
  };

  return (
    <Modal
      openModal={open}
      setOpenModal={setOpen}
      showCloseIcon={true}
      onClose={handleOnClose}
    >
      <div className={styles.modal_padding}>
        {ReorderFiles ? (
          <div className={styles.file_preview}>
            <PdfSvg />
            <p className={styles.file_name}>{ReorderFiles.name}</p>
            <IconButton
              aria-label='close-file'
              onClick={() => setReorderFiles(null)}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                color: 'black',
                zIndex: 1,
              }}
            >
              <CloseRounded />
            </IconButton>
          </div>
        ) : (
          <div className={styles.inputs}>
            <label
              htmlFor='prescriptionUpload'
              onClick={() => setOpenReorderUploads(true)}
              className={`${
                openReorderUploads ? styles.inputs__box_selected : ''
              } ${styles.inputs__box}`}
            >
              <UploadIcon className={styles.icon} />
              <p>Upload ReOrder Files</p>
            </label>
          </div>
        )}

        {openReorderUploads && (
          <SelectReorderFiles setReorderFiles={setReorderFiles} />
        )}
        <button
          onClick={handleSubmit}
          disabled={ReorderFiles == null}
          className={`prx-btn ${ReorderFiles == null && styles.disabled_btn}`}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default UploadCSVModal;

import React, { useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import {
  deletePurchaseOrder,
  getPurchaseOrders,
  updatePurchaseOrderStatus,
} from 'utils/api';
import { Autocomplete, TextField } from '@mui/material';
import { vendorOptions } from 'utils/constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';

const PurchaseOrders = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [purchaseItems, setPurchaseItems] = useState([]);
  const [masterDrugCodes, setMasterDrugCodes] = useState('');
  const [selectedZohoIds, setSelectedZohoIds] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const fetchPurchaseOrderItems = async () => {
    try {
      setLoading(true);
      let data = {
        data: {
          masterDrugCodes: masterDrugCodes
            .split(',')
            .map((drugCode) => parseInt(drugCode))
            .filter(Boolean),
          vendorZohoIds: selectedZohoIds.map((option) => option.id),
        },
        params: {
          skip: 0,
          limit: 1500,
        },
      };
      const response = await getPurchaseOrders()(data);
      if (response) {
        setPurchaseItems(response.data.data);
      }
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      setLoading(true);
      const data = { id: id };
      const response = await deletePurchaseOrder()(data);
      if (response.data.success === 1) {
        fetchPurchaseOrderItems();
      }
    } catch (error) {
      console.error('Error deleting PO:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPurchaseOrderItems();
  }, [masterDrugCodes, selectedZohoIds]);

  const UpdatePO = async (updatedItem, oldItem, markAsClosed) => {
    try {
      setLoading(true);

      const data = {
        id: updatedItem.id,
        data: {
          receivedQuantity: Number(updatedItem.receivedQuantity),
          purchaseRate: parseFloat(updatedItem.purchaseRate),
          eta: updatedItem.eta,
          markAsClosed: markAsClosed,
        },
      };

      const response = await updatePurchaseOrderStatus()(data);

      if (response.data.success === 1) {
        fetchPurchaseOrderItems();
        setRowModesModel((prev) => ({
          ...prev,
          [updatedItem.id]: { mode: 'view' },
        }));
      }
    } catch (error) {
      console.log('Error saving item:', error);
      return oldItem;
    } finally {
      setLoading(false);
    }
    return updatedItem;
  };

  const processRowUpdate = (updatedItem, oldItem) => {
    return UpdatePO(updatedItem, oldItem);
  };

  const handleProcessRowEditStart = (params, event) => {
    const { quantity, receivedQuantity } = params.row;
    if (quantity === receivedQuantity) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleDrugCodeClick = (zohoId) => {
    navigate(
      `/procurement-dashboard/purchase-orders-item-level?zohoid=${zohoId}`
    );
  };

  const columns = [
    {
      field: 'id',
      headerName: 'P.O. ID',
      width: 150,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            cursor: 'pointer',
            color: 'var(--primary-color)',
            textDecoration: 'underline',
          }}
          onClick={() => handleDrugCodeClick(params.row.zohoPurchaseOrderId)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      width: 150,
      editable: false,
    },
    {
      field: 'zohoPurchaseOrderId',
      headerName: 'Purchase Order ID',
      width: 250,
      editable: false,
    },
    {
      field: 'vendorZohoId',
      headerName: 'Vendor',
      width: 200,
      renderCell: (params) => {
        const vendor = vendorOptions.find((v) => v.id === params.value);
        return vendor ? vendor.name : 'Unknown';
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      type: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(params.id);
            }}
            color='error'
          />,
        ];
      },
    },
  ];

  const rows = purchaseItems?.map((item) => ({
    id: item.id,
    vendorZohoId: item?.vendorZohoId,
    zohoPurchaseOrderId: item?.zohoPurchaseOrderId,
    orderDate: dayjs(item.createdAt).format('YYYY-MM-DD'),
  }));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='home'>
        <h1>
          <b>Purchase Orders</b>
        </h1>
        <div className='filter-header'>
          <TextField
            variant='outlined'
            fullWidth
            label='Enter master drug code (Comma separated if multiple)'
            onChange={(e) => setMasterDrugCodes(e.target.value)}
          />
          <Autocomplete
            multiple
            options={vendorOptions}
            getOptionLabel={(option) => option.name}
            value={selectedZohoIds}
            onChange={(event, newValue) => setSelectedZohoIds(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select Zoho Vendors'
                variant='outlined'
              />
            )}
            style={{ width: '90%', borderRadius: '15px' }}
          />
        </div>{' '}
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          loading={loading}
          autoHeight
          slots={{ toolbar: GridToolbar }}
          editMode='row'
          isCellEditable={(params) =>
            params.row.receivedQuantity !== params.row.quantity
          }
          processRowUpdate={processRowUpdate}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          processRowEditStart={handleProcessRowEditStart}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'var(--grey-2)',
              fontWeight: 'bold',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};

export default PurchaseOrders;

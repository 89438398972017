import axios from 'axios';
import apiConfig from '../config.js';

const isTokenExpiredError = (errorResponse) => {
  if (
    errorResponse?.status === 401 &&
    errorResponse?.statusText === 'Unauthorized'
  ) {
    return true;
  }
  return false;
};

const getErrorsInArray = (errorResponse) => {
  let errors = [];

  if (
    Array.isArray(errorResponse.data) &&
    typeof errorResponse.data[0].message === 'string'
  ) {
    errorResponse.data.forEach((error) => {
      errors.push(error.message);
    });
  } else if (
    Array.isArray(errorResponse.data.error) &&
    typeof errorResponse.data.error[0] === 'string'
  ) {
    errorResponse.data.error.forEach((error) => {
      errors.push(error);
    });
  } else if (
    Array.isArray(errorResponse.data.error) &&
    typeof errorResponse.data.error[0].details[0].message === 'string'
  ) {
    errors.push(errorResponse.data.error[0].details[0].message);
  } else if (typeof errorResponse.data.error === 'string') {
    errors.push(errorResponse.data.error);
  } else if (typeof errorResponse.data.message === 'string') {
    errors.push(errorResponse.data.message);
  } else errors.push('Something Went Wrong');

  return errors;
};

const displayErrors = (errorResponse) => {
  if (errorResponse?.status >= 400 && errorResponse?.status <= 500) {
    return getErrorsInArray(errorResponse);
  } else return [];
};

const createAxios = () => {
  const config = {};

  let baseURL = apiConfig.apiUrl;

  config.baseURL = baseURL;

  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorResponse = error.response;
      if (isTokenExpiredError(errorResponse)) {
        if (typeof window !== 'undefined' && window.location.pathname !== '/') {
          console.log('You are logged out!');
          window.location.pathname = '/';
        }
        localStorage.removeItem('platinumRxDashboardToken');
      } else {
        const errors = displayErrors(errorResponse);
        errors.map((error) => {
          console.log(error);
        });
      }
      return Promise.reject(error);
    }
  );

  const getAuthorizationHeaders = () => {
    const token = localStorage.getItem('platinumRxDashboardToken');
    if (!token) return {};

    return { Authorization: `Bearer ${token}` };
  };

  const getCustomHeaders = () => {
    return {
      'device-id': localStorage.getItem('device'),
      'session-id': localStorage.getItem('session'),
      user_id: localStorage.getItem('userID'),

      url: window.location.href,
    };
  };

  const get = ({ url, data, params, headers, config = {} }) =>
    axiosInstance.request({
      method: 'GET',
      url,
      headers: {
        ...getAuthorizationHeaders(),
        ...getCustomHeaders(),
        ...headers,
      },
      data,
      params,
      ...config,
    });

  const post = ({ url, data, params, headers = {}, config = {} }) =>
    axiosInstance.request({
      method: 'POST',
      url,
      headers: {
        ...getAuthorizationHeaders(),
        ...getCustomHeaders(),
        ...headers,
      },
      data,
      params,
      ...config,
    });

  const put = ({ url, data, params, config = {} }) =>
    axiosInstance.request({
      method: 'PUT',
      url,
      headers: {
        ...getAuthorizationHeaders(),
        ...getCustomHeaders(),
      },
      data,
      params,
      ...config,
    });

  const patch = ({ url, data, params, config = {} }) =>
    axiosInstance.request({
      method: 'PATCH',
      url,
      headers: {
        ...getAuthorizationHeaders(),
        ...getCustomHeaders(),
      },
      data,
      params,
      ...config,
    });

  const remove = ({ url, data, config = {} }) =>
    axiosInstance.request({
      method: 'DELETE',
      url,
      headers: {
        ...getAuthorizationHeaders(),
        ...getCustomHeaders(),
      },
      data,
      ...config,
    });

  return {
    instance: axiosInstance,
    get,
    post,
    put,
    patch,
    delete: remove,
  };
};

export default createAxios;
